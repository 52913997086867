.item {


  &__top-panel {

    margin-bottom: 40px;

    @media screen and (max-width: 862px) {
      display: none;
    }

    &__title {

      h1 {
        margin: 0;
        margin-bottom: 8px;
        font-size: 30px;
        font-weight: 500;
        line-height: 1.4;
      }
    }

    &__vendor-code {
      font-size: 20px;
      margin-top: 8px;
    }

  }

  &-first-block {
    display: flex;
    flex-direction: row;

    margin-bottom: 100px;


    @media screen and (max-width: 960px) {
      padding: 0 60px 0 20px;
    }
    @media screen and (max-width: 842px) {
      flex-wrap: wrap;
    }
    @media screen and (max-width: 600px) {
      padding: 0 20px;
      margin-bottom: 60px;
    }

  }

  &__gallery {

    display: flex;
    flex-direction: row;

    overflow: hidden;
    flex: 1.4;
    min-width: 350px;

    @media screen and (max-width: 842px) {
      margin-bottom: 40px;

    }
    @media screen and (max-width: 621px) {
      flex: auto;
      width: 100%;
      max-width: 300px;
    }

    &__carousel {

      margin-top: 20px;
      margin-right: 8px;
      display: flex;
      flex-direction: column;
      min-width: 86px;
      width: 86px;

      &__arrow {
        cursor: pointer;
        max-width: 30px;
        opacity: .8;
        margin: 0 auto;

        &__i {
          max-width: 100%;
          object-fit: cover;
        }
      }

      &__c {
        max-height: 410px;
        overflow-y: hidden;
        margin-bottom: 10px;

        @media screen and (max-width: 621px) {
          max-height: 300px;
        }

      }
    }

    &__img {

      display: flex;
      flex-direction: column;
      border-radius: 12px;

      @media screen and (max-width: 842px) {
        flex: auto;
        width: 100%;
        max-width: 500px;
      }
      @media screen and (max-width: 621px) {
        flex: auto;
        width: 100%;
        max-width: 300px;
      }
      

      &__i {
        max-width: 100%;
        object-fit: cover;
        border-radius: 12px;
        max-height: 100%;
        min-height: 550px;

        @media screen and (max-width: 621px) {
          max-width: 96%;
          min-height: 400px;
        }
      }
    }
  }

  &__info {

    flex: 1.5;
    padding-left: 70px;
    padding-top: 20px;

    @media screen and (max-width: 930px) {
      padding-left: 40px;
    }

    @media screen and (max-width: 842px) {
      padding: 0;
      flex: auto;
      width: 100%;
    }

    &__mobile-panel {

      display: none;

      @media screen and (max-width: 862px) {
        display: block;
      }

      &__title {
        h1 {
          font-size: 24px;
          font-weight: 500;
        }
      }

      &__vendor-code {
        font-size: 15px;
      }
    }

    &__row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }

    &__prices {
      margin-top: 25px;
      margin-bottom: 45px;

      display: flex;
      flex-direction: row;
      align-items: center;

      &__current {
        font-size: 30px;
        font-weight: 600;
        padding-right: 25px;
        color: #f05656;

        @media screen and (max-width: 862px) {
          font-size: 22px;
        }
      }

      &__old {
        font-weight: 400;
        font-size: 24px;
        padding-right: 25px;
        text-decoration: line-through;
        
        opacity: .8;

        @media screen and (max-width: 862px) {
          padding-right: 5px;
          font-size: 18px;
        }
      }

      &__discount {
        font-size: 20px;

        opacity: .8;

      }
    }

    &__ccs {

      margin-bottom: 52px;

      &__title {
        size: 15px;
        font-weight: 500;

        margin-bottom: 25px;

        span {
          font-weight: 400;
        }
      }

      &__c {

        display: flex;
        flex-direction: row;

        align-items: center;

        &__arrow {

          &-left {
            margin-right: 25px;
          }

          &-right {
            margin-left: 25px;
          }

          &__i {
            max-width: 24px;
            width: 24px;
            max-height: 24px;
            height: 24px;
            object-fit: cover;
            cursor: pointer;
          }
        }

        &__carousel {
          position: relative;

          display: flex;
          flex-direction: row;

          max-width: 365px;
          overflow-x: hidden;
          overflow-y: clip;
        }
        
      }
    }

    &__sizes {
      margin-bottom: 36px;
      display: flex;
      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 480px) {
        .m-r-28 {
            margin-right: 20px;
        }
        .btn-link {
          padding: 0px;
        }
      }

    }

    &__btns {
      display: flex;
      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 480px) {
        flex-wrap: wrap;

        .m-r-28 {
          margin-right: 0px;
          margin-bottom: 20px;
        }

        .btn {
          padding: 16px 20px;
          width: 100%;
          justify-content: center;
        }
      }


    }

  }
  
}

#gallery-carousel-arrow-up {
  visibility: hidden;
}

#ccs-carousel-arrow-left { display: none; }
#ccs-carousel-arrow-right { display: none; }

.cc-mini-card {
  cursor: pointer;
  width: 100px;
  min-width: 100px;
  height: 133px;
  border: 2px solid transparent;

  @media screen and (max-width: 862px) {
    min-width: 80px;
    width: 80px;
    height: auto;
  }

  &:not(:last-child) { 
    margin-right: 25px;

    @media screen and (max-width: 862px) {
      margin-right: 20px;
    }
  }

  &__i {
    max-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  &--active {
    border: 2px solid #f27676;
  }
}

.gallery-mini-card {
  border-radius: 8px;
  margin-top: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all .3s ease;

  width: 80px;
  height: 100px;
  overflow: hidden;

  &--active {
    border: 2px solid #f27676;
    pointer-events: none;
  }

  &__i {
    max-width: 100%;
    object-fit: cover;
  }
}

.item-characteristics {

  margin: 0 auto;
  margin-bottom: 100px;

  max-width: 1440px;
  display: flex;
  flex-direction: row;
  padding: 0 180px;

  @media screen and (max-width: 1200px) {
    padding: 0 80px;
  }

  @media screen and (max-width: 1000px) {
    padding: 0 40px;
  }

  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
  
  &__params {
    width: 450px;
    margin-right: 30px;

    @media screen and (max-width: 700px) {
      margin-right: 0px;
      margin-bottom: 40px;
    }
  }

  &__additional {
    width: 555px;
  }
  
}

.item__params {

  &__title {
    margin-bottom: 16px;
    h2 {
      font-weight: 500;
      font-size: 24px;
    }
  }

  &__param {

    margin-top: 9px;
    font-size: 14px;
    line-height: 24px;
    
    span {
      font-weight: 600;
    }

  }
  
}

.additional-info {

  &__menu {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    border-bottom: 1px solid #ccc;
    overflow-x: auto;

    &__option {
      margin-right: 35px;
      padding: 7px 3px 11px 3px;
      border-bottom: 2px solid transparent;
      opacity: 0.8;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;

      &--active {
        border-bottom: 2px solid #484848;
        opacity: 1;
      }
    }
  }

  &__c {
    margin: 20px;
    transition: all .3s ease;
    line-height: 1.6;

    &__span {
      margin-bottom: 10px;
    }

    &__point {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
      font-size: 15px;
      margin-bottom: 6px;

    }

    &__sub-title {
      margin-top: 14px;
      margin-bottom: 7px;
      font-weight: 600;
    }

    li {
      margin-bottom: 6px;
      font-weight: 400;
      font-size: 14px;
    }
  }

}

#addi-description-c { }
#addi-care-c {
  display: none;
}

.item-reviews {

  margin: 0 auto;
  max-width: 1200px;
  margin-bottom: 120px;
  padding: 0 180px;

  @media screen and (max-width: 1000px) {
    padding: 0 40px;
  }

  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
  

  &__title {
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: #484848;

    margin-bottom: 40px;
  }

  &__c {
    margin-bottom: 40px;

    &__info {

      display: flex;
      flex-direction: row;

      margin-bottom: 32px;

      &__count {
        font-size: 20px;
        line-height: 32px;
      }

      &__rating {

        margin-left: auto;

        display: flex;
        flex-direction: row;
        align-items: center;

        .star {
          padding-top: 1px;
        }

      }
    }
  }

  &__btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__nothing {
    margin-bottom: 30px;
    text-align: center;
    line-height: 24px;
  }
}

.item-review {

  margin-bottom: 30px;

  &__author {
    margin-bottom: 12px;
    font-size: 14px;
    opacity: .8;
  }

  &__rating {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 8px;

  }

  &__text {
    font-weight: 500;
    line-height: 24px;
  }
}

.star {
  width: 16px;
  height: 15px;
  opacity: 0.2;

  &--active {
    opacity: 1;
  }
}

.item-size-picker {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-size-picker .nice-select {
  width: 190px !important;

  @media screen and (max-width: 480px) {
    width: 170px !important;
  }
  
}

#go-to-cart-button {
  display: none;
}

.line-climp-10 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
}

#additional-info__c__more {
  display: none;
}