

.splide__arrow {
  z-index: 999 !important;
}


.top-offer-carousel {
  position: relative;
  margin: 0 auto;
  max-width: 1600px;

  height: 440px;

  margin-left: 50px;
  margin-right: 50px;
  margin-top: 23px;
  border-radius: 20px;
  overflow: hidden;


  @media screen and (max-width: 822px) {
    height: 360px;
  }
  @media screen and (max-width: 560px) {
    height: 260px;
  }

  &__arrows {
  }

  &__container {
    margin: 0 auto;
    max-width: 1600px;
    display: none;
    --opacity: 0;

    @media screen and (max-width: 560px) {
      .splide__track {
        height: 260px;
      }
    }
  }


  &__block {
    height: 100%;
    position: relative;


  }

  &__block-1 {
      background: linear-gradient(rgba(0 0 0 / 50%), rgba(0 0 0 / 50%)), url('/static/images/promo/1Q1A45061-min.jpg');
      background-attachment: scroll;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
  }

  &__block-2 {
      background: linear-gradient(rgba(0 0 0 / 50%), rgba(0 0 0 / 50%)), url('/static/images/promo/57zrsOJ9YCA.jpg');
      background-attachment: scroll;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
  }

  &__block-3 {
      background: linear-gradient(rgba(0 0 0 / 50%), rgba(0 0 0 / 50%)), url('/static/images/promo/t17fgk_EfA0.jpg');
      background-attachment: scroll;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
  }

  &__block-4 {
      background: linear-gradient(rgba(0 0 0 / 50%), rgba(0 0 0 / 50%)), url('/static/images/promo/9qrmaew-HO4.jpg');
      background-attachment: scroll;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
  }

  &__block__info {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  &__block__info-1 {
      max-width: 70%;
      margin: 0 auto;
      text-align: center;
  }

  &__block__info__title {
      font-size: 40px;
      font-weight: 600;
      line-height: 62px;
      text-transform: uppercase;
      text-shadow: black 2px 2px 4px;

      @media screen and (max-width: 650px) {
        font-size: 35px;
        font-weight: 700;
      }
      @media screen and (max-width: 560px) {
        --margin-bottom: 20px;
      }
      @media screen and (max-width: 450px) {
        font-size: 30px;
      }
  }

  &__block__info__title h1 {
      font-size: 65px;
  }

  &__block__info__after-title {
      margin-top: 15px;
      font-size: 40px;
      font-weight: 600;
      line-height: 1.5;
      text-transform: uppercase;
      text-shadow: #000 2px 2px 4px;
  }

  &__container-mobile {
    margin: 0 auto;
    max-width: 1600px;
    display: none;
    --opacity: 0;

    & .top-offer-carousel__block {
      width: 100%;
      min-height: 400px;
    }
  }

  &__arrow-left {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px !important;
    z-index: 100;
  
    padding: 0px 20px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    cursor: pointer;

    @media screen and (max-width: 757px) {
      display: none;
    }
  }

  &__arrow-right{
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px !important;
    z-index: 100;
    
    padding: 0px 20px;
    
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 757px) {
      display: none;
    }
  }

  &__arrow__i{
    max-width: 30px;
  }

  &__arrow-left-mobile{
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 100;
    
    padding: 0px 14px;
    
    display: none;
    flex-direction: column;
    justify-content: center;
    
    cursor: pointer;

    @media screen and (max-width: 757px) {
      display: flex;
    }

    & .top-offer-carousel__arrow__i{
      max-width: 11px;
    }
  }

  &__arrow-right-mobile{
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 100;
    
    padding: 0px 14px;
  
    cursor: pointer;

    display: none;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 757px) {
      display: flex;
    }

    & .top-offer-carousel__arrow__i {
      max-width: 11px;
    }
  }

  & .slick-slide{
    height: 440px;
  }

  &__info{
    
    z-index: 99;
    position: absolute;
    top: 23%;
    right: 0px;
    left: 170px;
    bottom: 0px;
        
    display: flex;
    flex-direction: column;
    
  }

  &__info__c{
    color: #fff;
    max-width: 750px;
  }

  &__info__c__sub-title{
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  &__info__c__title{
    font-size: 65px;
    font-weight: 600;
    margin-bottom: 40px;
    line-height: 1.5;

    @media screen and (max-width: 1070px) {
      font-size: 45px;
    }

    @media screen and (max-width: 650px) {
      font-size: 35px;
      font-weight: 700;
    }

    @media screen and (max-width: 560px) {
      margin-bottom: 20px;
    }
  }

  &__info__c__badge{
    font-size: 30px;
    font-weight: 600;
    padding: 15px 40px;
    background: linear-gradient(101.03deg, #F27676 1.28%, #B01C90 100%);
    
    display: table;

    @media screen and (max-width: 1070px) {
      font-size: 25px;
    }
    @media screen and (max-width: 650px) {
      font-size: 22px;
      padding: 15px 20px;
    }
    @media screen and (max-width: 450px) {
      font-size: 22px;
      padding: 10px;
    }
  }

  &__block__info__description{
    margin-top: 15px;
    font-size: 18px;
    line-height: 32px;
    text-transform: uppercase;
    text-shadow: black 2px 2px 4px;
  }

  &__block__info__description h2 {
    font-weight: 400;
  }

  &__block__info__button{
    margin-top: 20px;
    text-transform: uppercase;
  }

  &__block__info__description-2{
    margin-top: 0px;
    
  }

  &__block__info__arrow{
    margin-top: 25px;
  }

  &__block__info-2{
    margin-left: 60px;
    max-width: 55%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__block__info-2 .top-offer-carousel__block__info__title {
  text-shadow: none;
  }

  &__block__info-2 .top-offer-carousel__block__info__description {
  text-shadow: none;
  }

}



@media screen and (min-width: 1700px) {
	.top-offer-carousel{
    margin: 0 auto;
    margin-top: 23px;
	}
}
@media screen and (max-width: 800px) {
  .top-offer-carousel{
    margin-left: 50px;
    margin-right: 50px;
	}
}
@media screen and (max-width: 700px) {
  .top-offer-carousel{
    margin-left: 20px;
    margin-right: 20px;
	}
}
@media screen and (max-width: 500px) {
	.top-offer-carousel{
    margin-left: 10px;
    margin-right: 10px;
	}
}





@media screen and (max-width: 650px) {
  .top-offer-carousel__container-mobile .top-offer-carousel__block{
    min-height: 350px;
  }
}






@media screen and (max-width: 1070px) {
  .top-offer-carousel__info__c__sub-title {
    font-size: 35px;
  }
  .top-offer-carousel__info__c__title {
    font-size: 45px;
  }
  .top-offer-carousel__info__c__badge {
    font-size: 25px;
  }
}
@media screen and (max-width: 850px) {
  .top-offer-carousel__info {
    right: 80px;
    left: 80px;
  }
}
@media screen and (max-width: 650px) {
  .top-offer-carousel__info {
    right: 60px;
    left: 60px;
  }
  .top-offer-carousel__info__c__sub-title {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .top-offer-carousel__info__c__title {
    font-size: 35px;
    font-weight: 700;
  }
  .top-offer-carousel__info__c__badge {
    font-size: 22px;
    padding: 15px 20px;
  }
}
@media screen and (max-width: 450px) {
  .top-offer-carousel__info{
    right: 40px;
    left: 40px;
  }
  .top-offer-carousel__info__c__sub-title {
    font-size: 25px;
    margin-bottom: 15px;
  }
  .top-offer-carousel__info__c__title {
    font-size: 30px;
  }
  .top-offer-carousel__info__c__badge {
    font-size: 22px;
    padding: 10px;
  }
}
@media screen and (max-width: 374px) {
  .top-offer-carousel__info {
    right: 20px;
    left: 20px;
  }
}
