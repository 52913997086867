@import 'item-card.scss';

.catalog {
  margin: 0 auto;
  max-width: 1600px;
  
  margin-top: 80px;
  display: flex;
  padding: 0px 50px;

  @media only screen and (max-width: 930px) {
    margin-top: 20px;
  }
  @media only screen and (max-width: 920px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 673px) {
    margin-top: 0px;
    padding: 0 20px;
  }

  &__slide-top {
    
    cursor: pointer;
    position: fixed;
    bottom: 30px;
    left: 30px;
    border-radius: 4px;
    background-color: #efefef;
    padding: 10px;
    width: 60px;
    height: 60px;
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 800px) {
      display: none!important;
    }

    &__i {
      max-height: 35px;
      object-fit: cover;
    }

    &:hover {
      background-color: #E1E1E1;
    }
  }

  &__menu {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 930px) {
      max-width: 100%;
      margin-bottom: 40px;
    }
    @media screen and (max-width: 673px) {
      padding: 0 10px;

      margin-top: 20px;
    }
    @media screen and (max-width: 574px) {
      width: 100%;
    }
     
    &__options {
      display: flex;
      flex-direction: column;

      border-bottom: 1px solid #c4c4c4;
      padding-bottom: 20px;

      @media screen and (max-width: 920px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }
      @media only screen and (max-width: 570px) {
        border-bottom: none;
      }
      @media screen and (max-width: 552px) {
        justify-content: space-between;
      }

      &__option {
        padding: 17px 0px;
        cursor: pointer;
        transition: all 0.2s ease;
        position: relative;
        font-weight: 500;
        line-height: 22px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 930px) {
          width: 200px;
        }
        @media screen and (max-width: 648px) {
          width: 175px;
        }
        @media screen and (max-width: 574px) {
          width: 160px;
          --margin-right: 10px;
          padding: 10px 10px 10px 0;
          font-size: 15px;
        }
        @media screen and (max-width: 552px) {
          width: auto;
        }  
        @media screen and (max-width: 524px) {
          width: 45%;
          margin-bottom: 15px;
          font-size: 18px;
        }
        
        &--active {
          color: #f05656;
        }
      }

      .emoji-30 {
        font-size: 40px;
        padding-right: 20px;

        @media screen and (max-width: 574px) {
          font-size: 32px;
          padding-right: 14px;
        }
      }

      .icon-1{
        max-width: 38px;
      }
      .icon-2{
          max-width: 58px;
      }
     
      &__option-active{
          color: #F05656;
          --border-bottom: 2px solid #F05656;
      }
      
      &__option:hover {
          color: #F05656;
          --border-bottom: 2px solid #F05656;

      }
    }

    &__filter {
      max-width: 220px;
      min-width: 220px;
      margin-top: 20px;

      @media only screen and (max-width: 920px) {
        max-width: 100%;
      }
      @media only screen and (max-width: 570px) {
        display: none;
      }

      &__title {
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 30px;
      }

      &__c {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &__option {
          flex: 1;
          min-width: 110px;
          max-width: 110px;
          display: none;
          flex-flow: row nowrap;
          margin-bottom: 8px;
          cursor: pointer;

          &-filter {
            margin-top: 15px;
          }

          &-refresh {
            margin-top: 15px;
            text-decoration: underline;
            font-size: 14px;
            cursor: pointer;
            --padding-left: 25px;
          }

          &-sizes-table {
            margin-top: 15px;
            text-decoration: underline;
            font-size: 14px;
            cursor: pointer;
          }

          &-check-all {
            margin-top: 15px;
            text-decoration: underline;
            font-size: 14px;
            cursor: pointer;
          }

          label {
            margin-left: 6px;
          }
        }
      }
    }

    .mob-filter-size-box {
      display: none;
    }

    &__filter-mobile {
      display: none;
      flex-direction: row;
      justify-content: center;
      margin-top: 0px;

      @media only screen and (max-width: 570px) {
        display: flex;
      }
    }
  }

  .filter-btn {
    
    margin: 0 auto;
    
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid rgba(242 118 118 / 70%);
    color: #484848;
    padding: 17px 15px;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    transition: all .2s ease;
    cursor: pointer;
    
    width: 203px;

    &__i{
      max-height: 24px;
      max-width: 24px;
      margin-right: 10px;
    }

    &:hover{
      background-color: #F05656;
    }
  }
  
  &__c{
    flex-grow: 1;
    
    display: flex;
    flex-direction: column;
    
    margin-left: 50px;

    @media (max-width: 1234px) {
      margin-left: 20px;
    }

    @media (max-width: 900px) {
      margin-left: 0px;
    }
    
  }

  &__content {
      margin-top: 46px;
      display: flex;
      flex-flow: row wrap;
      width: auto;

      @media only screen and (max-width: 920px) {
        justify-content: center;
      }

      @media screen and (max-width: 862px) {
        justify-content: center;
      }

      @media screen and (max-width: 720px) {
        margin-top: 10px;
        justify-content: space-around;
      }

      @media screen and (max-width: 639px) {
        margin-bottom: 80px;
      }

      &__title-breaker {
        margin-top: 20px;
        width: 200px;
        border-bottom: 2px solid #f27676;
        
        @media screen and (max-width: 720px) {
          margin-top: 0px;
        }
        @media screen and (max-width: 574px) {
          margin: 0 auto;
          margin-top: 20px;
          width: 100px;
        }
      }

      &__title h1 {
        font-size: 28px;
        text-transform: uppercase;
        font-weight: 300;
        line-height: 1.5;
        color: #484848;

        @media screen and (max-width: 1033px) {
          font-size: 24px;
        }
        @media screen and (max-width: 955px) {
          font-size: 28px;
        }
        @media screen and (max-width: 748px) {
          font-size: 24px;
        }
        @media screen and (max-width: 673px) {
          font-size: 20px;
        }
        @media screen and (max-width: 574px) {
          margin: 0 auto;
          text-align: center;
          font-size: 18px;
        }
        
      }

      &__title h2, .catalog__content__title h1 {
        font-size: 28px;
        text-transform: uppercase;
        font-weight: 300;
        line-height: 1.5;
        color: #484848;
      }
  }

  &-container {
    min-height: 500px;
    margin-bottom: 120px;

    &__no-results {
      display: none;
      
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
      color: #484848;
    }
  }
}