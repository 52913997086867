.page-checkout {

  @media screen and (max-width: 840px) {
    .m-b-70 {
      margin-bottom: 30px;
    }
  }

  &__records {
    width: 80%;
    max-width: 1300px;
    margin: 0 auto;

    @media only screen and (max-width: 1200px) {
      width: 96%;
    }
    
  }


  @media screen and (max-width: 960px) {
    h1 {
        padding: 0 60px;
    }
  }
  @media screen and (max-width: 840px) {
    h1 {
      padding: 0 40px;
    }
  }


  @media screen and (max-width: 1095px) {
    h1 {
        font-size: 36px;
    }
  }
  @media screen and (max-width: 986px) {
    h1 {
        font-size: 30px;
    }
  }
  @media screen and (max-width: 822px) {
    h1 {
        font-size: 26px;
    }
  }
  @media screen and (max-width: 560px) {
    h1 {
      padding: 0 20px;
    }
  }
}

.checkout {

  &-info-row {

    display: flex;
    flex-direction: row;
    align-items: center;
    
    padding: 20px 40px;

    @media screen and (max-width: 622px) {
      padding: 20px 10px;
    }

    &__title {
      font-size: 34px;
      font-weight: 500;

      @media screen and (max-width: 930px) {
        font-size: 24px;
      }

    }

    &__btn {
      margin-left: auto;

      @media screen and (max-width: 930px) {
        font-size: 16px;
      }

      @media screen and (max-width: 452px) {
        display: none;
      }

    }
  }

  &-row {
    margin: 0 auto;
    border-top: 1px solid #999;
    padding: 20px 40px 20px 40px;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 452px) {
      padding: 20px 10px 0px 10px;
    }

    .row {
      flex-grow: 1;

      display: flex;
      flex-direction: row;

      justify-content: space-around;

      @media screen and (max-width: 800px) {
        flex-wrap: wrap;
        justify-content: flex-start;

        .collapse {
          display: none;
        }
      }
    }

    &__img {
      min-width: 110px;
      max-width: 110px;
      height: 170px;
      overflow: hidden;
      flex: .8;
      border-radius: 8px;
      margin-right: 30px;
      cursor: pointer;

      @media screen and (max-width: 574px) {
        margin-right: 16px;
      }
      @media screen and (max-width: 786px) {

      }
      
      &__i {
        margin-left: 0;
        width: 117px;
        min-height: 100%;
        object-fit: contain;
      }
    }

    &__info {
      width: 210px;
      margin-right: 20px;

      @media screen and (max-width: 800px) {
        width: 100%;
        text-align: left;
      }

      @media screen and (max-width: 600px) {
        margin-bottom: 10px;
      }

      &__name {
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 10px;

        cursor: pointer;

        @media screen and (max-width: 574px) {
          font-size: 16px;
        }

      }

      &__cc {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 10px;
      }

      &__price {
        font-weight: 500;
        
      }

      &__warn {
        margin-top: 10px;
        font-weight: 600;
        color: #f1223a;
      }
    }

    &__sizes {

      display: flex;
      flex-direction: column;
      justify-content: center;

      min-width: 170px;
      margin-right: 20px;

      @media screen and (max-width: 800px) {
        width: 100%;
        text-align: left;
      }

      &__picker {
        display: flex;
        flex-direction: column;

        height: 28px;
        margin-bottom: 15px;

        .nice-select {
          height: 28px;
          line-height: 26px;
          padding-left: 8px;
          padding-right: 20px;
        }
      }

      &__btn {
        text-decoration: underline;
      }

    }

    &__count {

      display: flex;
      flex-direction: column;
      justify-content: center;

      min-width: 160px;
      margin-right: 20px;

      @media screen and (max-width: 800px) {
        width: 100%;
        text-align: left;
      }

      &__picker {
        display: flex;
        flex-direction: column;

        height: 28px;
        margin-bottom: 15px;

        @media screen and (max-width: 600px) {
          margin-bottom: 0px;
        }

        .nice-select {
          height: 28px;
          line-height: 26px;
          padding-left: 8px;
          padding-right: 20px;
        }
      }

      &__total-cost {
        font-size: 14px;
        font-weight: 500;

        @media screen and (max-width: 450px) {
          font-weight: 400;
        }

        span {
          font-weight: 600;
        }
      }

    }

    &__actions {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__delete {
        padding: 10px;
        width: 75px;
        text-align: right;

        @media screen and (max-width: 1300px) {
          width: auto;
        }

        &__i {
          max-width: 24px;
          cursor: pointer;
        }
      }
    }
  }
  

  &-records {
    

  }

  &-block {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 80px;

    display: flex;
    flex-direction: column;

    padding: 0px 20px;
    
    @media screen and (max-width: 850px) {
      width: auto;

    }

    @media screen and (max-width: 700px) {
      align-items: center;
    
      padding: 0px 30px;
      margin-bottom: 50px;

    }

    &__title {

      font-weight: 500;
      font-size: 25px;
      text-align: left;

      margin-bottom: 40px;

      @media screen and (max-width: 700px) {
        text-align: center;
        margin-bottom: 30px;
      }

      @media screen and (max-width: 500px) {
        font-size: 20px;
      }

    }

    &__disclaimer {
      font-weight: 500;
      font-size: 14px;
      -webkit-text-decoration-line: underline;
      text-decoration-line: underline;
      margin-bottom: 40px;
    }

    &__delivery-cards {
      margin-bottom: 50px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @media screen and (max-width: 700px) {
        justify-content: center;
        margin-bottom: 10px;
      }

      &__card {
        min-width: 253px;
        text-align: center;
        margin-right: 15px;
        margin-bottom: 15px;
        padding: 30px;
        background: #efefef;
        box-shadow: 0 4px 4px rgba(0,0,0,.25);
        border-radius: 5px;
        cursor: pointer;
        transition: .2s ease all;

        @media screen and (max-width: 700px) {
          margin-right: 0px;
        }

        &:hover {
          background: #f27676;
          color: #fff;
        }

        &--active {
          background: #f27676;
          color: #fff;
        }
      }
    }

    &__payment-cards {
      margin-bottom: 50px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @media screen and (max-width: 700px) {
        justify-content: center;
        font-size: 14px;
        margin-bottom: 10px;
        
      }

      &__card {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        margin-right: 30px;
        margin-bottom: 20px;
        min-width: 230px;
        width: 230px;
        height: 40px;
        background: #fff;
        box-shadow: 2px 2px 5px rgba(0,0,0,.25);
        border-radius: 15px;
        padding: 20px;
        cursor: pointer;
        transition: .1s ease all;
        font-weight: 500;
        color: #484848;

        &--disabled {
          pointer-events: none;
          opacity: 0.6;
        }

        @media screen and (max-width: 700px) {
          margin-right: 0px;
        }

        &-invoice {
          flex-wrap: wrap;

          @media screen and (max-width: 600px) {
            text-align: center;
          }

          @media screen and (max-width: 600px) {
            margin-bottom: 20px;
            margin-right: 0px;
          }
        }

        &__i {
          width: 40px;
          height: 40px;
          margin-right: 20px;
          margin-bottom: 0px;
          object-fit: contain;

    
          &-invoice {
            @media screen and (max-width: 450px) {
              margin-left: 20px;
              margin-right: 20px;
              margin-bottom: 10px;
            }
          }

          
        }

        &__data { 

          margin-top: 5px;

          &-min {
            display: flex;
            flex-basis: 0;
            min-width: 105px;
          }

          &__title {
            margin-bottom: 5px;

            &__unavailable {
              padding-top: 3px;
              font-size: 13px;
            }

            @media screen and (max-width: 600px) {
              margin-bottom: 10px;
            }
          }

          &__text {
            margin-bottom: 10px;
          }

          &__row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 10px;

            @media screen and (max-width: 500px) {
              margin-top: 0;
              justify-content: center;
              text-align: center!important;
            }

            &__text {

              &-left {
                @media screen and (max-width: 600px) {
                  margin-bottom: 8px;
                  text-align: center;
                }
              }

              &-right {
                text-align: right;
                margin-left: 10px;

                @media screen and (max-width: 600px) {
                  margin-left: 0px;
                  text-align: center;
                }
              }
            }

          }

          &__noti {
            color: #484848;
          }

        }

        &:hover {
          background-color: #F27676;
          color:#fff;
        }

        &--active {
          background-color: #F27676;
          color:#fff;
        }
      }
    }

    &__regions {

      max-width: 750px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      margin-bottom: 30px;

      @media screen and (max-width: 700px) {
        margin-top: 20px;
        padding: 0px 20px;
      }

      &__info {
        font-weight: 500;
        font-size: 18px;
        text-decoration-line: underline;

        margin-bottom: 40px;

        min-width: 90%;
      }

      &__region {

        max-width: 293px;
        min-width: 293px;
        margin-bottom: 25px;
        margin-right: 60px;

        @media screen and (max-width: 700px) {
          max-width: auto;
          min-width: auto;
          margin-right: 0px;
        }

        &--disabled {
          opacity: .5;
          pointer-events: none;
        }
      }
    }

    &__input {
      margin-bottom: 20px;
      font-size: 18px;
      display: flex;
      flex-direction: column;

      &__title {
        margin-bottom: 15px;
      }

      &__i {
        input.input-delivery {
          min-width: 600px;
          max-width: 600px;

          @media screen and (max-width: 700px) {
            min-width: auto;
            max-width: auto;
          }
        }

        input.input-draft {

          @media screen and (max-width: 700px) {
            width: 100%;
            box-sizing: border-box;
          }
        }

        input.input-promocode {
          width: 350px;

          @media screen and (max-width: 600px) {
            width: 100%;
            box-sizing: border-box;
          }
        }
      }
    }

    &__textarea {
      margin-bottom: 20px;
      font-size: 18px;
      display: flex;
      flex-direction: column;

      &__title {
        margin-bottom: 15px;
      }

      &__t {
        textarea.textarea-draft {
          width: auto;
          max-width: 250px;
          min-width: 250px;
          height: 236px;
          max-height: 236px;
          min-height: 236px;

          @media screen and (max-width: 700px) {
            max-width: 230px;
            min-width: 230px;

            max-width: none;
            width: 100%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }
        }
      }
    }

    &__draft {

      display: flex;
      flex-direction: row;
      align-items: flex-start;

      @media screen and (max-width: 1122px) {
         flex-wrap: wrap;
      }

      &__col {

        display: flex;
        flex-direction: column;

        max-width: 280px;
        padding: 20px 15px;

        @media screen and (max-width: 650px) {
          max-width: 100%;
          min-width: 80%;
          width: auto;
          flex-grow: 1;
          padding-right: 0px;
          padding-left: 0px;
        }

        @media screen and (max-width: 600px) {
          padding-top: 10px;
        }

        &-total {
          padding-left: 25px;
          padding-right: 25px;

          max-width: 330px;
          width: 330px;
          min-width: 220px;

          @media screen and (max-width: 500px) {
            padding-left: 0px;
            padding-right: 0px;

            max-width: 100%;
            width: auto;
            flex-grow: 1;
          }

        }

      }

    }
  }

}

.delivery-type-checkbox {

  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  &__i {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 5px;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  &__info {

    margin-left: 20px;
    display: flex;
    flex-direction: column;

    &__title {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;
    }

    &__price {
      font-weight: 500;
      font-size: 16px;

      span {
        font-weight: 700;
      }
    }

  }

}

.row-bottom-mobile {
  display: none;

  padding: 20px;
  padding-top: 15px;
  padding-bottom: 30px;

  .checkout-row__actions {
    margin-left: auto;
  }

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.delivery-type-checkbox input:checked ~ .delivery-type-checkbox__checkmark:after {
  display: block;
}

.delivery-type-checkbox .delivery-type-checkbox__checkmark:after {
  left: 11px;
  top: 4px;
  width: 6px;
  height: 15px;
  border: solid #f27676;
  border-width: 0 2px 2px 0;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#delivery-regions-0 { display: none; }
#delivery-regions-1 { display: none; }
#input-delivery-c { display: none; }

#payment-card-invoice-text { display: none; }
#payment-card-invoice-row { display: none; }
#payment-card-invoice-noti { display: none; }

.draft {

  &__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 15px;
  }

  &__row {

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    margin-bottom: 15px;

    &__title {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      text-align: left;
      
      &__info {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        text-align: left;
        color: #a3a3a3;
        margin-top: 5px;
      }
    }
    &__value {
      margin-left: auto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }

  &__policy {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 1.4;
    color: #999;
  }

  &__btn {

  }
}

.checkout-records-closure {
  border-top: 1px solid #999;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 40px 20px 40px;

  margin-bottom: 80px;

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;

    padding: 20px 20px;
  }

  @media screen and (max-width: 700px) {
    padding: 20px;
    margin-bottom: 50px;
    
  }

  &__promocode {

    @media screen and (max-width: 1000px) {
      margin-top: 10px;
      margin-bottom: 30px;
      min-width: 50%;
    }

    @media screen and (max-width: 700px) {
      display: none;
    }

    &-mobile {
      display: none;

      @media screen and (max-width: 700px) {
        display: block;

        width: 100%;
      }
    }

    &__title {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #484848;

      
    }

    &__input {
      margin-bottom: 10px;
      position: relative;
    }
  }

  &__info {

    margin-left: auto;
    margin-right: 100px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1000px) {
      margin-left: 0;
      margin-right: 0;
    }

    @media screen and (max-width: 700px) {
      margin-bottom: 30px;

      width: 100%;
    }

    &__row {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;

      margin-bottom: 13px;

      &-font-16 {
        font-size: 16px;
      }
      &-font-18 {
        font-size: 18px;
      }
      &-font-24 {
        font-size: 24px;
      }

      &__title {
        width: 250px;
        text-align: right;
        margin-right: 25px;

        @media screen and (max-width: 1000px) {
          text-align: left;
        }

        @media screen and (max-width: 700px) {
          width: auto;
        }

      }

      &__value {

        display: flex;
        flex-direction: row;
        justify-content: center;

        width: 140px;
        text-align: center;

        @media screen and (max-width: 700px) {
          text-align: right;
          justify-content: flex-end;
          margin-left: auto;
        }

        &-crossed {
          text-decoration: line-through;
        }

        &__red {
          margin-right: 17px;
          width: 60px;
          text-align: center;
          color: red;
        }
        &__green {
          color: #0c6;
        }
      }
    }
  }
}

.checkout-promocode-btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(101.03deg,#f27676 1.28%,#b01c90 100%);
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  width: 150px;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#closure-promocode-valuation {
  display: none;
}


#page-checkout-empty {
  display: none;
}
.page-checkout-empty {
  margin: 0 auto;
  padding: 80px;

  &__title {
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
    font-size: 34px;
    font-weight: 600;
  }

  &__emoji {
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
    font-size: 44px;
    font-weight: 600;

    &__i {
      max-width: 58px;
    }
  }

  &__description {
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 40px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}