.page-faq {
  max-width: 1600px;
  margin: 0 auto;
  margin-top: 60px;
  padding: 0 80px;

  @media screen and (max-width: 960px) {
    padding: 0 60px;
  }
  @media screen and (max-width: 840px) {
    padding: 0 40px;
    margin-top: 30px;
  }
  @media screen and (max-width: 560px) {
    padding: 0 20px;
  }

  &__title {

    h1 {
      margin: 0px;
      padding: 0px;
      font-weight: 600;
      font-size: 36px;

      @media screen and (max-width: 1260px) {
        font-size: 40px;
      }
      @media screen and (max-width: 1095px) {
        font-size: 36px;
      }
      @media screen and (max-width: 986px) {
        font-size: 30px;
      }
      @media screen and (max-width: 822px) {
        font-size: 26px;
      }
      @media screen and (max-width: 622px) {
        font-size: 24px;
      }
      
    }

    margin-bottom: 50px;
  }

  &__c {

    line-height: 1.4;

    &__subtitle {

      h2 {
        margin: 0px;
        padding: 0px;
        font-weight: 600;
        font-size: 24px;

        margin-bottom: 20px;
      }

      h3 {
        margin: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 20px;
      }

    }

    &__text {
      max-width: 900px;

      margin-bottom: 16px;
    }
    &__payment-card {
      margin-bottom: 16px;

      display: flex;
      flex-direction: row;
      align-items: center;

      &__i {
        max-width: 60px;
        margin-right: 10px;
      }
    }

    ul {
      margin: 0px;
      margin-bottom: 20px;

      li {
        max-width: 900px;
        padding-bottom: 6px;
        
      }

    }
  }

  .m-t-10 {
    margin-top: 10px;
  }
  .m-b-50 {
    margin-bottom: 50px;
  }

}

.faq-q {
  margin-bottom: 22px;

  &__title {
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
    transition: all .3s ease;
    text-decoration: underline;

    &__i {
      max-width: 20px;
      vertical-align: middle;
      margin-left: 10px;
      transition: all .3s ease;
    }

    .rotated {
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
    }
  }

  &__answer {
    margin-top: 18px;
    line-height: 22px;
    padding-bottom: 20px;
  }
}

#faq-q-answer-0 { display: none; }
#faq-q-answer-1 { display: none; }
#faq-q-answer-2 { display: none; }