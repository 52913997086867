.page {
  margin: 0 auto;
  max-width: 1440px;
  margin-top: 40px;
  padding: 0 80px;


  @media screen and (max-width: 1060px) {
    padding: 0 60px 0 20px;
  }

  @media screen and (max-width: 842px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 840px) {
    padding: 0 40px 0 20px;
  }

  @media screen and (max-width: 560px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 490px) {
    padding: 0 0px;
  }

  &__title {

    h1 {
      padding: 0 80px;
      font-weight: 400;
      font-size: 36px;
    }
  }
  
  &__breadcrumbs {

    margin-bottom: 40px;

    margin-left: -20px;
    margin-right: -20px;

    padding: 0px 20px;

    @media screen and (max-width: 600px) {
      margin-left: 0px;
      margin-right: 0px;
    }

    a {
      display: flex;
      flex-direction: row;
      align-items: center;

      width: fit-content;
    }

    &__i {
      max-width: 10px;
      object-fit: cover;
      padding-right: 12px;
      cursor: pointer;
    }

    &__link {
      color: #8d8d8d;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__c {

    &__sub-title {

      h2 {
        margin: 0px;
        padding: 0px;
        font-weight: 600;
        font-size: 24px;
      }

      h3 {
        margin: 0px;
        padding-top: 10px;
        padding-bottom: 6px;
        font-size: 20px;
      }
    }

    &__text {

    }
  }

}