.payment-page__status {
  padding: 0 80px;
  font-size: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 20px;

  @media screen and (max-width: 960px) {
    padding: 0 60px;
  }
  @media screen and (max-width: 840px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 560px) {
    padding: 0 20px;
  }

  &__i {
    max-width: 30px;
    object-fit: cover;
    margin-left: 8px;
    margin-right: 8px;
  }
}

.payment-page__info {
  padding: 0 80px;
  font-size: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 960px) {
    padding: 0 60px;
  }
  @media screen and (max-width: 840px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 560px) {
    padding: 0 20px;
  }
}
