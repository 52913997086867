
.footer {
  margin-top: 60px;
  
  background-color: #eeeeee;
  
  padding: 80px 50px 60px 50px;

  @media screen and (max-width: 862px) {
    padding: 40px 20px 40px 20px;
  }

  .footer__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 1600px;
    margin: 0 auto;
  
    @media only screen and (max-width: 890px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  .footer__container-copyright {
    max-width: 1600px;
    margin: 0 auto;
    padding: 50px 20px 0px 20px;
    justify-content: flex-start;
  }

  .footer__column {
    flex: 1;
    max-width: 260px;
    min-width: 200px;
    margin: 0px 20px;

    @media only screen and (max-width: 890px) {
      margin-top: 20px;
    }
  }

  .footer__column-centered {
    text-align: center;
  }

  .footer__column__i {
    width: 200px;
    margin-top: -10px;
    margin-bottom: 20px;
  }
  
  .footer__column__title {
    font-size: 20px;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 30px;
  }

  .footer__column__options {
    display: flex;
    flex-direction: column;
  }

  .footer__column__options a{
    color: #484848;
    text-decoration: none;
  }

  .footer__column__options__s {
    margin-bottom: 14px;
    font-weight: 500;
    font-size: 16px;
  }

  .footer__column__options__s a {
    color: #484848;
  }
  
  .footer__column__options__a {
    margin-bottom: 14px;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    display: table;
  }

  .footer__column__options__a:hover {
    color: #F27676;
    transition: all 0.2s ease;
  }

  .footer__column__options__row {
    display: flex;
    flex-direction: row;
    
  }

  .footer__column__options__row__i {
    max-height: 40px;
    margin: 3px;
  }
}