.item-card {
  display: table;
  border-radius: 12px 12px 0px 0px;
  width: 300px;
  min-width: 300px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  position: relative;

  @media only screen and (max-width: 920px) {
    width: 31%;
    min-width: auto;
  }
  @media screen and (max-width: 862px) {
    width: 275px;
    min-width: 275px;
  }
  @media screen and (max-width: 639px) {
    width: 164px;
    min-width: 164px;
    margin: 20px 5px;
  }


  & a {
    text-decoration: none;
    color: #484848;

  }

  &__img {
    position: relative;
    overflow: hidden;
    padding: 20px 20px 0px 20px;

    @media screen and (max-width: 639px) {
      padding: 0;
    }

    &__i {
      max-width: 100%;
      min-height: 350px;
      object-fit: cover;
      border-radius: 8px;

      @media screen and (max-width: 639px) {
        min-height: 0;
      }
    }

    &__discount {
      position: absolute;
      left: 0px;
      bottom: 0px;
      
      background: linear-gradient(101.03deg, #F27676 1.28%, #B01C90 100%);
      
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
  
      color: #FFFFFF;
      
      padding: 3px;
      
      display: none;

      @media screen and (max-width: 639px) {
        display: block;
      }
    }
  }

  &__buy-in-one-mobile{
    display: none;
    font-size: 12px;
    line-height: 15px;
  
    text-decoration-line: underline;
  
    color: #F1223A;

    @media screen and (max-width: 639px) {
      display: block;
    }
  }

  &__info {
    padding: 0px 26px;
    margin-top: 5px;
    margin-bottom: 15px;
    max-width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;

    @media screen and (max-width: 639px) {
      padding: 0;
      font-size: 14px;
      line-height: 17px;
      margin-top: 15px;
      margin-bottom: 10px;
    }
  }

  &__info__artcode {
    font-size: 14px;
  }

  &__price {
    padding: 0px 26px;
    margin-top: 19px;
    font-size: 18px;
    display: flex;
    flex-direction: row;

    white-space: nowrap;

    @media screen and (max-width: 639px) {
      display: none;
    }

  }

  &__price-mobile {
    padding: 0px 26px;
    margin-top: 19px;
    font-size: 18px;
    display: none;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 639px) {
      padding: 0;
      display: flex;
    }
  }

  &__price-mobile__col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__price__buy {
    background: #F27676;
    border-radius: 8px;
    padding: 7px;
    height: 18px;
  }
  &__price__buy__i {
    width: 18px;
    height: 18px;
  }

  &__price__cur {
    font-weight: 500;
    
    display: flex;
    flex-direction: row;
    
    margin-right: 13px;

    @media screen and (max-width: 639px) {
      font-size: 16px;
      margin-bottom: 5px;
      margin-right: 15px;
    }
  }

  &__price__old {
    margin-right: 13px;
    font-weight: 500;
    text-decoration: line-through;
    color: #B4B4B4;
    
    @media screen and (max-width: 639px) {
      font-size: 12px;
      margin-right: 15px;
    }
  }

  &__price__discount {
    
    font-weight: 500;
    color: #F27676;
    font-size: 13px;

    @media screen and (max-width: 639px) {
      display: none;
    }
    
  }

  &__buttons {
    padding: 0px 22px 16px 22px;
    display: flex;
    flex-direction: row;
  }

  &__sizes {
    
    padding: 0px 24px 6px 24px;
  }

  &__expand {
    opacity: 0;
    height: 0;
    overflow: hidden;
    
    border-radius: 0px 0px 12px 12px;
    width: 300px;
        
    z-index: 100;
    
    background-color: #fff;
      
    transition: all 0.2s ease;

    @media screen and (max-width: 639px) {
      display: none;
    }
  }

  &__sizes__title {
    --font-weight: 500;
    color: #B4B4B4;
  }

  &__sizes__c {
    margin-top: 10px;
    display: flex;
    flex-flow: row wrap;
    max-width: 250px;
  }

  &__sizes__c__size {
    color: #484848;
    margin-right: 15px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  &__button-one-click {
    
    padding: 0 24px;
    margin-bottom: 24px;

    & a {
      padding: 4px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      color: #F27676;
      text-decoration: underline;
      transition: all 0.3s ease;

      & :hover {
        color: #F05656;
        text-decoration: none;
      }
    }
  }

  .btn-checkout__i {
    content: "";
    background: url("/static/images/icons/checkout.svg") left center no-repeat;
    width: 22px;
    background-size: contain;
    height: 20px;
    margin-right: 4px;
  }

  .btn-checkout-pred__i {
      content: "";
      background: url("/static/images/icons/checkout-black.svg") left center no-repeat;
      width: 22px;
      background-size: contain;
      height: 20px;
      margin-right: 4px;
      transition: all 0.3s ease;
  }

  &-btn {
    
    cursor: pointer;
    
    margin: 0px 3px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 4px;
    padding: 8px 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &-grey {
      color: #333;
      border: 1px solid #999;
      transition: all 0.3s ease;

      &:hover {
        color: #eee;
        background-color: #333;
        border: 1px solid #999;
      }

      &:hover .btn-checkout-pred__i {
        content: "";
        background: url("/static/images/icons/checkout.svg") left center no-repeat;
        width: 22px;
        background-size: contain;
        height: 20px;
        margin-right: 4px;
      }
    }

    &-green {
      color: #fff;
      background-color: #00B247;
      transition: all 0.3s ease;

      &:hover {
        color: white;
        background-color: #00D856;
      }

      &:hover .btn-checkout__i {
        content: "";
        background: url("/static/images/icons/checkout.svg") left center no-repeat;
        width: 22px;
        background-size: contain;
        height: 20px;
        margin-right: 4px;
      }
    }

    &-pink-gradient {
      color: white;
      transition: all 0.2s ease;

      background-color: #F27676;
    }

    &-pink-gradient:hover {
        background-color: #F05656;
    }

    &-pink-gradient-2 {
        color: white;
        transition: all 0.2s ease;

        background: linear-gradient(101.03deg, #F27676 1.28%, #B01C90 100%);
    }

    &-pink-gradient-2:hover {
        opacity: 0.9;
    }

  }

  &:hover {
    background-color: #F6F6F6;
  }

  &:hover .item-card__expand {
    position: absolute;
    background-color: #F6F6F6;

    opacity: 1;
    height: auto;
  }

}