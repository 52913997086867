.page-contacts {
  max-width: 1600px;
  margin: 0 auto;
  margin-top: 60px;
  padding: 0 80px;

  @media screen and (max-width: 960px) {
    padding: 0 60px;
  }
  @media screen and (max-width: 840px) {
    padding: 0 40px;
    margin-top: 30px;
  }
  @media screen and (max-width: 560px) {
    padding: 0 20px;
  }

  &__title {
    margin-bottom: 50px;

    @media screen and (max-width: 986px) {
      margin-bottom: 30px;
    }

    h1 {
      margin: 0px;
      padding: 0px;
      font-weight: 600;
      font-size: 36px;

      @media screen and (max-width: 1260px) {
        font-size: 40px;
      }
      @media screen and (max-width: 1095px) {
        font-size: 36px;
      }
      @media screen and (max-width: 986px) {
        font-size: 30px;
      }
      @media screen and (max-width: 822px) {
        font-size: 26px;
      }
      @media screen and (max-width: 622px) {
        font-size: 24px;
      }
    }

  }

  &__c {

    line-height: 1.4;

    &__subtitle {

      h2 {
        margin: 0px;
        padding: 0px;
        font-weight: 600;
        font-size: 24px;

        margin-bottom: 20px;
      }

      h3 {
        margin: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 20px;
      }

    }

    &__text {
      max-width: 900px;

      margin-bottom: 16px;
    }

    &__important {
      font-size: 26px;
      font-weight: 500;

      @media screen and (max-width: 986px) {
        font-size: 22px;
      }
    }
  }

  .m-t-10 {
    margin-top: 10px;
  }
  .m-b-50 {
    margin-bottom: 50px;
  }

}
