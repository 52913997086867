

.popup {

  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.45);
  z-index: 10100;

  &-close {
    cursor: pointer;
    width: 30px;
    height: 26px;
    padding-top: 4px;
    display: inline-block;
    position: absolute;
    top: 26px;
    right: 26px;
    transition: ease .25s all;
    -webkit-transform: translate(50%,-50%);
    transform: translate(50%,-50%);
    border-radius: 1000px;
    background: rgba(0,0,0,.8);
    font-family: Arial,Sans-Serif;
    font-size: 20px;
    text-align: center;
    line-height: 100%;
    color: #fff;
  }

  &__c {
    
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    border-radius: 18px;
    background: #fff;
    overflow: hidden;

    &-add-to-cart {
      max-width: 395px;
      max-height: 620px;
      width: 90%;

      display: flex;
      flex-direction: column; 
    }

    &-buy-in-one {
      max-width: 700px;
      max-height: 620px;
      width: 90%;
      height: 90%;
      
      display: flex;
      flex-direction: row;

      @media screen and (max-width: 560px) {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
    }

    &-size-chart {
      position: relative;
      max-width: 900px;
      max-height: auto;
      height: fit-content;
      width: 90%;
      border-radius: 18px;
      background: #fff;

      display: flex;
      flex-direction: row;

      overflow: hidden;

      table {
        border-collapse: collapse;
        border-radius: 1em;
        overflow: hidden;
        border-spacing: 0;
        text-align: left;
      }

      tr:nth-child(even) {
        background-color: #f2f2f2;
      }

      tr th {
        background-color: #f27676;
        padding: 16px 28px 16px 28px;
        color: #fff;
      }

      tr td {
        padding: 14px 28px 14px 28px;
        font-weight: 600;
        min-width: 73px;
      }
    }

    &-new-review {
      max-width: 600px;
      max-height: 620px;
      width: 90%;

      padding: 44px;
      
      overflow-y: auto;
    }

    &-mobile-filter {
      max-width: 700px;
      max-height: 620px;
      width: 90%;
      
      overflow: hidden;
    }

    &__col {
      display: flex;
      flex-direction: column;

      overflow-y: auto;
      
      &-right {
        flex: 3;
        padding: 44px;
      }

      &__img {

        width: 280px;
        min-width: 280px;
        height: 100%;
        position: relative;
        overflow: hidden;
        display: flex;

        @media screen and (max-width: 798px) {
          display: none;
        }
        
        &__i {
          min-height: 100%;
          max-height: 100%;
          min-width: 100%;
          object-fit: cover;
        }
      }

      &__title {
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 35px;
      }

      &__vendor-code {
        padding-bottom: 25px;
      }

      &__input {
        margin-bottom: 30px;
        font-size: 18px;
        display: flex;
        flex-direction: column;
  
        &__title {
          font-size: 15px;
          margin-bottom: 10px;
        }

        &__rating {
          display: flex;
          flex-direction: row;

          flex-direction: row-reverse;
          justify-content: flex-end;

          .star {
            cursor: pointer;

            width: 22px;
            height: 22px;
          }
        }
  
        &__i {
          display: flex;

          .input-new-review {
            width: auto;
            flex-grow: 1;
          }

          .textarea-new-review {
            width: auto;
            flex-grow: 1;

            min-height: 50px;
            max-height: 100px;
          }

          input.input-delivery {
            min-width: 600px;
            max-width: 600px;
          }
  
          input.input-draft {
            width: 240px;
          }
  
          input.input-promocode {
            width: 350px;
          }
        }
      }

      &__btns {
        display: flex;
        margin-bottom: 20px;
      }

      &__disclaimer {
        font-weight: 500;
        font-size: 14px;
        text-decoration-line: underline;
        margin-bottom: 35px;
      }
    }

    &__title {
      margin: 30px 20px 45px 30px;
      font-weight: 500;
      font-size: 20px;
      line-height: 1.5;
    }

    &__row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      margin: 0px 20px 45px 30px;

      &-mobile-filter-sizes {
        margin-bottom: 10px;

        .mobile-filter__c__option {
          flex: 1;
          min-width: 110px;
          max-width: 110px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          margin-bottom: 8px;
          cursor: pointer;
        }
      }

      .size-in-a-box {
        cursor: pointer;
        padding: 10px 16px;
        border: 1px solid #e0e0e0;
        margin-right: 10px;
        margin-bottom: 15px;

        &--active {
          color:#fff;
          background-color:#F05656;
          border: 1px solid #F05656;
        }

        &:hover {
          color:#fff;
          background-color:#F05656;
          border: 1px solid #F05656;
        }
      }

      .mobile-filter-size-in-a-box {
        cursor: pointer;
        padding: 10px 16px;
        border: 1px solid #e0e0e0;
        margin-right: 10px;
        margin-bottom: 15px;

        &--active {
          color:#fff;
          background-color:#F05656;
          border: 1px solid #F05656;
        }

        &:hover {
          color:#fff;
          background-color:#F05656;
          border: 1px solid #F05656;
        }
      }
    }

    &__btns {
      display: flex;
      flex-direction: row;
      margin: 0px 20px 30px 30px;
      flex-wrap: wrap;
    }
  }
}

#catalog-go-to-cart-btn {
  display: none;
}

.new-review-star:hover ~ .new-review-star,
.new-review-star:hover {
	opacity: 1.0 !important;
}