
.block {
  max-width: 1600px;
  margin: 0 auto;
  margin-bottom: 120px;

  @media screen and (max-width: 704px) {
    margin-bottom: 60px;
  }

}

.block-title {
  width: 70%;
  margin: 0 auto;

  font-size: 34px;
  font-weight: 400;
  line-height: 49px;
  text-align: center;
  
  @media screen and (max-width: 639px) {
    font-size: 25px;
    margin: 0 20px;
  }

}

.block-title h5 {
  font-weight: 400;

}

.block-content {
  margin-top: 50px;

}

.block-content-mobile {
  display: none !important;
  margin-top: 50px;

}