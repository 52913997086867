
.help-block {
  padding: 0px 50px;
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 970px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 862px) {
    padding: 0 20px;
  }

  &__call {
    display: block;
    flex: 2;
    background-color: #eee;
    border-radius: 12px;
    padding: 48px;
    margin: 0 auto;
    margin: 10px;
    max-width: 580px;

    @media screen and (max-width: 970px) {
      min-width: 80%;
    }

    @media screen and (max-width: 552px) {
      padding: 28px;
      margin: 0;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 28px;

    @media screen and (max-width: 552px) {
      font-size: 20px;
      font-weight: 500;
    }
  }

  &__thanku {
    display: none;
    margin-top: 24px;
    font-weight: 400;
    line-height: 22px;
    font-size: 16px;
  }

  &__thanku__i {
    vertical-align: middle;
    width: 28px;
    height: 28px;
    margin-bottom: 7px;
  }

  &__description {
    margin-top: 24px;
    font-weight: 400;
    line-height: 22px;
    font-size: 16px;

    @media screen and (max-width: 552px) {
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__description__phone {
    margin: 25px 0px;
  }

  &__description a {
    font-weight: 500;
    font-size: 28px;

    color: #222;

    @media screen and (max-width: 552px) {
      font-weight: 500;
      font-size: 20px;
    }
  }

  &__input {
    margin-top: 34px;
    display: flex;
    flex-direction: row;

    margin-bottom: 30px;

    @media screen and (max-width: 552px) {
      flex-wrap: wrap;
    }

  }

  &__button {
    margin-top: 24px;
  }

  &__policy-info {
    margin-top: 30px;

    font-size: 12px;
    line-height: 1.4;
    color: #999;
  }

  &__description__des {
    display: block;

    @media screen and (max-width: 552px) {
      display: none;
    }
  }

  &__description__mobile {
    display: none;

    @media screen and (max-width: 552px) {
      display: block;
    }
  }
  
  &__social-media {
    display: block;
    flex: 1;
    background-color: #eee;
    border-radius: 12px;
    padding: 48px;
    margin: 0 auto;
    margin: 10px;
    max-width: 580px;

    @media screen and (max-width: 552px) {
      padding: 28px;
      margin: 0;
      margin-top: 20px;
    }

  }
  
  &__links {
    margin-top: 24px;
  }

  &__links .media-insta__i {
    margin-left: 0px;
    margin-right: 10px;
  }

  &__links .media-fb__i {
    margin-left: 0px;
    margin-right: 10px;
  }
  
  &__links .media-vk__i {
    margin-left: 0px;
    margin-right: 10px;
  }

}

@media screen and (max-width: 552px) {
  #phone-help-i {
    width: 100%;
  }
  #btn-phone-help {
    margin-left: 0;
    margin-top: 20px;
  }
}