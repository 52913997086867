

.catalog-banner {

  @media screen and (max-width: 1000px) {
    display: none;
  }
  
  &-pulltonic {
    margin-top: 70px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255 255 255 / 0%) 100%), linear-gradient(100.93deg, #FFE662 0%, #B01C90 100%);
  }

  &-pulltonic .catalog-banner__c__info {
    margin-right: 40px;
    margin-left: 20px;
  }

  &-tonymontana {
    background: linear-gradient(100.97deg, #E0E7FF 0%, #B2B2B2 99.34%);
  }

  &-tonymontana .catalog-banner__c__info{
    margin-left: 40px;
    margin-right: 20px;
  }

  &-sw4 {
    background: linear-gradient(15.46deg, #FFB9B9 10.83%, #E0E7FF 89.64%);
  }

  &-sw4 .catalog-banner__c__info {
    margin-right: 60px;
    margin-left: 20px;
  }

  &__c {
    max-width: 1440px;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
    
    padding-left: 50px;
    padding-right: 50px;

    &__img {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
    
      &__i {
        max-height: 400px;
        object-fit: cover;

        @media screen and (max-width: 452px) {
          max-height: 250px;
        }
      }

      &__i-2 {
        max-height: 500px;

        @media screen and (max-width: 452px) {
          max-height: 250px;
        }
      }

      &__i-3 {
        max-height: 500px;

        @media screen and (max-width: 452px) {
          max-height: 250px;
        }
      }
    }

    &__info {

      &__logo {
        margin-bottom: 35px;
      
        &__i {
          max-width: 180px;
          object-fit: cover;
        }

        &__i-tm {
          max-width: 290px;
          @media screen and (max-width: 560px) {
            max-width: 180px;
          }
        }

        &__i-pt {
          max-width: 240px;

          @media screen and (max-width: 560px) {
            max-width: 170px;
          }
        }

        &__i-sw4 {
          max-width: 250px;

          @media screen and (max-width: 560px) {
            max-width: 170px;
          }
        }
      }

      &__title {
        text-transform: uppercase;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 40px;

        @media screen and (max-width: 639px) {
          margin-bottom: 30px;

          font-size: 14px;
          font-weight: 500;
        }

        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
      }

      &__btns {
        display: flex;

        .btn {
          justify-content: center;
        }
      }
    }


  }


  &-mob {
    display: none;

    @media screen and (max-width: 1000px) {
      display: flex;
    }
  }

  &__c__info__title-black {
    color: #484848;
  }
 

}


@media screen and (max-width: 1300px) {
  .catalog-banner__c__img__i-1 {
    content: url("/static/images/banners/pulltonic-mob-2.png");
  }
}

@media screen and (max-width: 1000px) {
  .catalog-banner{
    display: none;
  }
  .catalog-banner-mob {
    display: block;
  }
  .catalog-banner-sw4 .catalog-banner__c__info__title-black {
    color: #484848;
  }
  .catalog-banner-tonymontana {
    display: block;
  }
  .catalog-banner__c {
    flex-direction: column;
  }
  .catalog-banner-pulltonic .catalog-banner__c__info {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 50px;
    text-align: center;
  }
  .catalog-banner-tonymontana .catalog-banner__c__info{
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 50px;
    text-align: center;
  }
  .catalog-banner-sw4 .catalog-banner__c__info {
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 50px;
    text-align: center;
  }
  .catalog-banner__c__info__btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .catalog-banner__c__info__title{
    font-size: 16px;
  }
}