
.review {
  cursor: pointer;

  &__c {
    background-color: #eee;
    margin: 10px !important;
    padding: 40px !important;

    border-radius: 12px;
    outline: 0;
  
  }

  &-basket {
    margin-top: 14px;
  }
  
  &-name {
    font-size: 24px;
    font-weight: 400;
  }
  
  &-text {
    margin-top: 18px;
    font-size: 16px;
    line-height: 22px;
  }

}

.store-reviews {
  --height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
}