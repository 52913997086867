
.blog {
  margin: 0 auto;
  max-width: 1660px;
  margin-top: 40px;
  margin-bottom: 50px;
  padding: 0px 20px;

  &__title {
    margin-bottom: 50px;

    @media screen and (max-width: 986px) {
      margin-bottom: 30px;
    }

    h1 {
      padding: 0 80px;
      font-weight: 500;
      font-size: 36px;

      @media screen and (max-width: 986px) {
        font-size: 30px;
        padding: 0 40px;
      }
      @media screen and (max-width: 822px) {
        font-size: 26px;
      }
      @media screen and (max-width: 622px) {
        font-size: 24px;
        padding: 0 20px;
        text-align: center;
      }
    }
  }

  &__c {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  &-card {
    margin: 8px;
    padding: 5px;
    flex-grow: 1;
    max-width: 340px;

    a {
      text-decoration: none;
      color: #484848;
    }

    &__img {
      max-width: 100%;
      max-height: 196px;
      height: 196px;

      cursor: pointer;
      overflow: hidden;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      margin-bottom: 18px;

      &__i {
        max-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }

    &__info {
      margin-bottom: 14px;

      &__date {
        font-weight: 400;
        font-size: 14px;
        padding-right: 4px;
      }

      &__categories {
        font-weight: 500;
        font-size: 14px;
        color: #f05656!important;
        text-decoration: none;
      }
    }

    &__title {
      margin-bottom: 14px;

      color: #484848;
      font-size: 28px;
      font-weight: 600;
      line-height: 35px;
      cursor: pointer;

      @media screen and (max-width: 986px) {
        font-size: 24px;
        line-height: 1.4;
      }
      @media screen and (max-width: 822px) {
        font-size: 22px;
      }
      @media screen and (max-width: 622px) {
        font-size: 20px;
      }
    }

    &__preview {
      font-size: 16px;
      line-height: 22px;

      @media screen and (max-width: 600px) {
        line-height: 1.3;
      }
    }
  }

}