
.cert__info {

  margin-bottom: 80px;

  display: flex;
  flex-direction: row;
  justify-content: center;


  @media only screen and (max-width: 890px) {
    margin-top: 40px;
  }
  @media screen and (max-width: 639px) {
    display: none;
  }
  @media screen and (max-width: 560px) {
      flex-wrap: wrap;
  }
  
  &-mobile-block{
    margin-bottom: 80px;
    display: none;

    @media screen and (max-width: 639px) {
      display: block;
    }
  }

  &__cert {
    width: 200px;
    margin: 20px;
    min-width: 180px;

    &-8 {
      margin: 8px !important;
    }
    
  }

  &__cert__i {
    max-width: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  &__cert__i:hover {
    box-shadow: 0 10px 10px rgba(0 0 0 / 20%);
    -moz-transform: translate(0px, -8px);
    -ms-transform: translate(0px, -8px);
    -o-transform: translate(0px, -8px);
    -webkit-transform: translate(0px, -8px);
    transform: translate(0px, -8px);
  }

}
