@import "header.scss";
@import "footer.scss";

@import "page.scss";
@import "page-delivery.scss";
@import "page-faq.scss";
@import "page-contacts.scss";

@import "blog.scss";
@import "article.scss";

@import "blocks.scss";
@import "inputs.scss";

@import "reviews.scss";
@import "certificates.scss";

@import "catalog.scss";
@import "item.scss";
@import "checkout.scss";

@import "popup.scss";

@import "payment.scss";

@import "animations.scss";
@import "toastify.scss";

@import "nice-select2.scss";

html {
	overflow-x: hidden;
}

html, body {
	margin: 0;
	padding: 0;
	font-family: Montserrat, sans-serif;
	color: #484848;

}

*, html {
	scroll-behavior: smooth !important;
}

.logo {
	&__i {
		width: 180px;

		@media screen and (max-width: 560px) {
			width: 160px;
		}

	}
}

.media-insta__i,
.media-vk__i,
.media-fb__i {
	padding: 4px 4px;
	margin-left: 10px;
	height: 40px;
	width: 40px;
	transition: all 0.3s ease;

}

.media-insta__i:hover {
	content: url("/static/images/icons/insta.png");

}

.media-fb__i:hover {
	content: url("/static/images/icons/fb.png");

}

.media-vk__i:hover {
	content: url("/static/images/icons/vk.png");

}

.text-center {
	text-align: center;
}
.jc-center {
	justify-content: center;
}

.text-300 {
	font-weight: 300;
}
.text-500 {
	font-weight: 500;
}
.text-600 {
	font-weight: 600;
}
.text-700 {
	font-weight: 700;
}
.text-white {
	color: white;
}
.text-red {
	color: red;
}
.text-pink {
	color: #F27676;
}
.text-green {
	color: #0c6;
}

.text-14 {
	font-size: 14px;

	
}
.text-18 {
	font-size: 18px;
}
.text-24 {
	font-size: 24px;
}

.row {
	display: flex;
	flex-direction: row;
}

.img-30 {
	width: 30px;
	height: 30px;
}

.m-r-28 {
	margin-right: 28px;
}
.m-r-12 {
	margin-right: 12px;
}
.m-r-7 {
	margin-right: 7px;
}
.m-r-4 {
	margin-right: 4px;
}
.m-r-3 {
	margin-right: 3px;
}

.m-b-70 {
	margin-bottom: 70px;
}
.m-b-60 {
	margin-bottom: 60px;
}
.m-b-50 {
	margin-bottom: 50px;
}
.m-b-40 {
	margin-bottom: 40px;
}
.m-b-30 {
	margin-bottom: 30px;
}
.m-b-20 {
	margin-bottom: 20px;
}
.m-b-15 {
	margin-bottom: 15px;
}

.m-v-19 {
	margin: 19px 0px;
}

.w-200 {
	width: 200px;
}

.tel-href {
	color: #f27676;
	font-weight: 500;

	font-size: 20px;
}
.email-href {
	color: #f27676;
	font-weight: 500;

	font-size: 20px;
}