.btn {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-weight: 600;
  border-radius: 8px;
  padding: 14px 32px 14px 32px;
  transition: all 0.2s ease;

  cursor: pointer;

  &-14 {
    font-size: 14px;

    @media screen and (max-width: 639px) {
      font-size: 12px;
    }
  }

  &-left-30 {
    margin-left: 30px;
  }

  &-pink {
    color: white;
    background-color: #F27676;

    &:hover {
      background: #F05656;
    }
  }

  &-item-cart {
    padding: 17px 20px;
    font-size: 14px;
  }

  &-purple {
    color: #fff;
    background: linear-gradient(101.03deg,#f27676 1.28%,#b01c90 100%);
    box-shadow: 0 2px 4px #bd4949;

    &:hover {
      opacity: 0.9;
    }
  }
  
  &-black {
    color: #fff;
    background: #484848;

    &:hover {
      background: #585858;
    }
  }

  &-black-2 {
    color: #484848;
    border: 2px solid #484848;

    &:hover {
      color: #fff;
      background-color: #484848;
    }
  }
  
  &-white {

    background: #fff;
    color: #000;

    &:hover {
      background: #eee;
    }

  }
  
  &-center {
    text-align: center;
  }

  &__more a {

    text-align: center;
    margin: 0 auto;
    margin-top: 40px;
    display: table;
    border-radius: 12px;
    background-color: #F27676;
    color: white;
    padding: 17px 38px;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s ease;
    cursor: pointer;

    &-black a {

      background-color: #111;
    
    }

    &:hover{
      background-color: #F05656;
      
    }

    &-black a:hover {
      background-color: #333;
      
    }
  }

  &-link {
    font-weight: 500;
    font-size: 14px;
    text-decoration: underline;
    color: #484848;
    cursor: pointer;

    padding: 0px;
  }

  &-sizes-chart {
    font-weight: 500;
    font-size: 14px;
    text-decoration: underline;
    color: #f27676;
    cursor: pointer;

  }

  &-grey {
    background: #f5f5f5;
    box-shadow: 0 2px 4px #e0e0e0;
    border: 2px solid #f5f5f5;
    font-size: 14px;
    color: #f27676;

    &:hover {
      background-color: #fff;
    }
  }

  &-buy-in-one {
    color: #f1223a;
    text-decoration: underline;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      
    }
  }

  &__i {
    width: 26px;
    height: 26px;
    margin-left: 10px;
  }

}

.common-input {
  padding: 12px 22px 12px 22px;
  outline: 0;
  border-radius: 8px;
  border: 2px solid transparent;
  font-size: 16px;
  color: #484848;
  transition: all 0.3s ease;
}

.common-input-white-bg {
  background-color: white;
}

.input {
  padding: 12px 22px 12px 22px;
  outline: 0;
  border-radius: 8px;
  font-size: 16px;
  color: #484848;
  background-color: #efefef;
  width: 250px;
  transition: all .3s ease;
  border: 2px solid transparent;
  font-family: Montserrat,sans-serif;

  &--error {
    border: 2px solid red;
  }
}

.textarea {
  padding: 12px 22px 12px 22px;
  outline: 0;
  border-radius: 8px;
  font-size: 16px;
  color: #484848;
  background-color: #efefef;
  width: 250px;
  transition: all .3s ease;
  border: 2px solid transparent;
  font-family: Montserrat,sans-serif;

  &--error {
    border: 2px solid red;
  }
}


.checkbox-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 9px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #efefef;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #f29999;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #f05656;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}