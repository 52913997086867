
.discount-offer-mini {
  margin-top: 120px;
  margin-bottom: 120px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &__title {
    font-weight: 600;
    font-size: 31px;
    line-height: 38px;
    color: #C9C9C9;
    text-align: center;
    margin-bottom: 35px;

    @media screen and (max-width: 700px) {
      font-size: 25px;
    }

  }
  
  &__row {
    display: flex;
    flex-direction: row;
    padding: 25px 0px;
  }

  &__row__col {
    width: 250px;
    margin: 0px 35px;

    @media screen and (max-width: 700px) {
      width: auto;
      flex: 1;
      margin: 0 10px;
    }
  }

  &__row__col__title {
    color: #F27676;
    font-weight: bold;
    font-size: 72px;
    line-height: 88px;
    margin-bottom: 15px;
    text-align: center;

    @media screen and (max-width: 700px) {
      font-size: 40px;
    }

  }

  &__row__col__text {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}


@media screen and (max-width: 800px) {
  .discount-offer__col-left{
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .discount-offer-mini__title{
    font-size: 25px;
  }
  .discount-offer-mini__row__col__title{
    font-size: 40px;
  }
  .discount-offer-mini__row__col{
    width: auto;
    flex: 1;
    margin: 0px 10px;
  }
}


.discount-offer{
  
  display: flex;
  flex-direction: row;
  max-height: 307px;
  overflow: hidden;

  margin-bottom: 120px;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
  
}
.discount-offer__col {
  flex: 1;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 800px) {
  .discount-offer .discount-offer__col {
    min-width: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .discount-offer__col-800 {
    display: none;
  }
}

.discount-offer__col__i {
  max-width: 100%;
  object-fit: cover;
}
.discount-offer__col-right {
  padding-left: 40px;
  
  justify-content: center;
  
  background: linear-gradient(101.03deg, #F27676 1.28%, #B01C90 100%);
}
.discount-offer__col-right-60 {
  padding-left: 60px;
}
.discount-offer__col__btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.discount-offer__col__title {
  font-weight: 600;
  font-size: 32px;
  color: #fff;
  text-transform: uppercase;
}
.discount-offer__col__text {
  margin-top: 18px;
  font-weight: 500;
  font-size: 20px;
  color: #fff;
}
.discount-offer__col__text__row span {
  font-size: 50px;
  margin-right: 20px;
}
.discount-offer__col__text__row {
  margin-top: 7px;
  margin-bottom: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .discount-offer .discount-offer__col-right {
    min-width: 300px;
    margin-left: 0px;
    padding: 50px 60px;
  }
}
@media screen and (max-width: 800px) {
  .discount-offer .discount-offer__col {
    min-width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 422px) {
  .discount-offer__col__title {
    font-size: 26px;
  }
}

@media screen and (max-width: 422px) {
  .discount-offer__col__text {
    font-size: 18px;
  }
}