.header {

	display: flex;
	flex-direction: column;
	max-width: 1680px;
	margin: 0 auto;

	&__row {
		display: flex;
		
		@media screen and (max-width: 960px) {
			padding: 7px 60px;
		}

		@media screen and (max-width: 840px) {
			padding: 7px 40px;
		}

		&-20 {
			margin-top: 15px;
			margin-bottom: 1px;
			
			@media screen and (max-width: 560px) {
				margin-top: 26px;
				margin-bottom: 1px;
			}
		}
		

		&-top {
			flex-direction: row;
			align-items: center;
			padding: 25px 50px 0 50px;
			z-index: 110;

			@media screen and (max-width: 840px) {
				padding: 25px 40px 0 40px;
			}

			@media screen and (max-width: 560px) {
				padding: 25px 20px 0 20px;
			}

		}

		&__logo {
			cursor: pointer;
		}

		&-menu {
			flex-direction: row;
			align-items: center;
			padding: 7px 50px 7px 50px;
			margin: 15px 0px 1px 0px;

			@media screen and (max-width: 840px) {
				padding: 7px 40px;
			}
	
			@media screen and (max-width: 560px) {
				padding: 1px 20px 7px 20px;

				margin-top: 26px;
				margin-bottom: 1px;
			}
			
		}

		&-fixed {
			flex-direction: row;
			align-items: center;

			// ???
			padding: 0 !important;
			width: 100%;
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			background-color: #fff;
			border-bottom: 1px solid #efefef;
			transition: all 0.2s ease;
			z-index: 999;

			&__container {
				display: flex;
				flex-direction: row;
				align-items: center;

				flex-grow: 1;

				width: auto;
				padding: 7px 50px;
				max-width: 1519px;
				margin: 0 auto;

				@media screen and (max-width: 960px) {
					padding: 7px 60px;
				}
				@media screen and (max-width: 840px) {
					padding: 7px 40px;
				}
				@media screen and (max-width: 560px) {
					padding: 7px 20px;
				}
			}

			.checkout-btn {

				display: flex;
				flex-direction: column;
				align-items: center;

				cursor: pointer;
				color: #666;
				--background-color: #F27676;
				border-radius: 12px;
				padding: 0 0 0 26px;
				transition: all 0.2s ease;
				text-decoration: none;
				font-weight: 500;

				&-row {
					display: flex;
					flex-direction: row;
					align-items: center;

					.checkout-btn__i {
						margin-bottom: 0px;
					}
				}

				@media screen and (max-width: 480px) {
					display: none;
				}

				&__i {
					height: 40px;
					width: 40px;
					margin-bottom: 9px;
					vertical-align: middle;	
			
				}
			
				&-mobile {
					display: none;
					flex-direction: row;
					align-items: center;
					border-radius: 12px;
					padding: 0px;

					@media screen and (max-width: 480px) {
						display: flex;
						
					}

					.checkout-btn__i {
						margin-bottom: 0;
						margin-left: 5px;
					}

				}

				&--disabled {
					opacity: 0.6;
					pointer-events: none;
					cursor: default;
				}

				&:hover{
					opacity: 0.8;
				}

			}

			.header__row__option-checkout {
				margin-right: 0px;
			}

		}

		&__options {
			display: flex;
			flex-direction: row;
			flex-basis: 100%;
			align-items: center;
			justify-content: flex-end;
		}

		&__option {
			padding: 8px 14px 8px 0;
			margin: 8px 24px 8px 0;
			cursor: pointer;
			font-weight: 500;
			transition: all .2s ease;
			font-size: 16px;
			z-index: 9999;

			@media screen and (max-width: 960px) {
				font-size: 18px;
			}

			&-menu {
				&-semi {
					display: none;

					@media screen and (max-width: 960px) {
						display: table;
					}
				}
			}

			&-checkout {
				visibility: hidden;
				margin: 0 24px 0 0;
				padding: 0;
				font-weight: 400;
				font-size: 16px;
				margin-left: auto;

				a {
					text-decoration: none;
				}
			}

			&__a {
				color: #666 !important;
				text-decoration: none;
				transition: all .2s ease;

				&:hover {
					color: #F05656 !important;
				}

			}
			&-menu-semi {
				padding: 8px 14px 3px 0;
			}
			
			&-menu-semi-left-auto {
				margin-left: auto;
				margin-right: 0;

				.header-burger-mobile {
					display: table;

					&__i {
						width: 30px;
						height: 20px;
						object-fit: cover;
				
					}
				}
			}

			&-collapse {
				@media (max-width: 1100px) {
					display: none;
				}
			}

		}

		&__checkout {
			margin-left: 40px;

			&__a {
				text-decoration: none;
			}

			.checkout-btn {

				display: flex;
				flex-direction: column;
				align-items: center;

				cursor: pointer;
				color: #666;
				--background-color: #F27676;
				border-radius: 12px;
				padding: 0 26px 0 26px;
				transition: all 0.2s ease;
				text-decoration: none;
				font-weight: 500;

				@media screen and (max-width: 480px) {
					display: none;
				}

				&__i {
					height: 40px;
					width: 40px;
					margin-bottom: 9px;
					vertical-align: middle;	
			
				}
			
				&-mobile {
					display: none;
					flex-direction: row;
					align-items: center;
					border-radius: 12px;
					padding: 8px 10px 4px 11px;

					@media screen and (max-width: 480px) {
						display: flex;
					}

					.checkout-btn__i {
						margin-bottom: 0;
						margin-left: 5px;
					}

				}

				&--disabled {
					opacity: 0.6;
					pointer-events: none;
					cursor: default;
				}

				&:hover{
					opacity: 0.8;
				}

			}
		}

	}

	&__contacts {

		@media screen and (max-width: 720px) {
    	display: none;
		}

		&__phone {
			margin-bottom: 3px;

			&__a {
				font-weight: 600;
    		font-size: 22px;

				color: #484848;
			}
		}

		&__time {

		}
	}

	&-menu {

		position: relative;
    display: flex;
    flex-direction: column;
    cursor: default;

		&__title {
			display: flex;
			flex-direction: row;
			align-items: center;
			z-index: 99;

			&__i {
				max-width: 18px;
				max-height: 18px;
				margin-left: 5px;
				object-fit: cover;
				transition: .2s ease all;
			}
		}

		&__options {
			position: absolute;
			top: -2px;
			left: -21px;
			padding-left: 8px;
			display: none;
			height: 300px;
			flex-direction: column;
			padding-top: 45px;
			background-color: #fff;

			@media (max-width: 600px) {
				display: none!important;
			}

			.header-menu__option-1:hover .header-menu__option__expand-1{
				display: flex;
				top: -45px;
			}
			.header-menu__option-2:hover .header-menu__option__expand-2{
				display: flex;
				top: -84px;
			}
			.header-menu__option-3:hover .header-menu__option__expand-3{
				display: flex;
				top: -123px;
			}
			.header-menu__option-4:hover .header-menu__option__expand-4{
				display: flex;
				top: -162px;
			}
		}

		&__option {
			font-size: 16px;
			background-color: #fff;
			display: flex;
			flex-direction: row;
			width: 238px;
			padding: 10px 13px;
			position: relative;
			cursor: pointer;

			&__i {
				margin-left: auto;
				max-width: 18px;
				max-height: 18px;
				object-fit: cover;
				
			}

			&__expand {
				display: none;
				position: absolute;
				top: -45px;
				left: 264px;
				height: 300px;
				flex-direction: column;
				padding-top: 45px;
				padding-left: 0;
				background-color: #fff;
			}

			&:hover {
				background-color: #efefef;
			}
		}

		&:hover {
			.header-menu__options {
				display: flex;
			}
			.header-menu__title__i {
				transform: rotate(180deg);
			}
		}
	}
}

#header-menu-semi {
	display: none;

	@media (max-width: 1100px) {
		display: block;
	}
}

.header-menu-mobile {
	display: none;
}
.checkout-btn-mobile {
	display: none;
}
.checkout-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	color: #666;
	--background-color: #F27676;
	border-radius: 12px;
	padding: 0 26px 0 26px;
	transition: all .2s ease;
	text-decoration: none;
	font-weight: 500;
}
.checkout-btn__i {
	max-width: 30px;
	max-height: 30px;
	margin-bottom: 0;
	margin-right: 6px;
}






.header__mobile-menu{
  display: none;

  z-index: 11000;

  background-color: rgba(0,0,0,0.4);

  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom:0px;
  flex-direction: column;

}
.header__mobile-menu__c{

  flex-direction: column;
  align-items: center;

  text-align: center;

  background-color: #fff;

  border-radius: 0px 0px 15px 15px;
  padding: 50px 20px 15px 20px;
}
.header__mobile-menu__option{
  margin-bottom: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.header__mobile-menu__close{
  margin-top: 40px;
  cursor: pointer;
}
.header__mobile-menu__close__i{
  width: 16px;
}

.header__mobile-catalog{
  display: none;

  z-index: 11000;

  background-color: rgba(0,0,0,0.4);

  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom:0px;
  flex-direction: column;

}
.header__mobile-catalog__c{

  flex-direction: column;
  align-items: center;

  text-align: center;

  background-color: #fff;

  border-radius: 0px 0px 15px 15px;
  padding: 50px 20px 15px 20px;
}
.header__mobile-catalog__option{
  margin-bottom: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.header__mobile-catalog__option__i{
  max-width: 18px;
  max-height: 18px;
  object-fit: cover;
}

.header__mobile-catalog__close{
  margin-top: 40px;
  cursor: pointer;
}
.header__mobile-catalog__close__i{
  width: 16px;
}
.header__mobile-catalog{
  display: none;

  z-index: 11000;

  background-color: rgba(0,0,0,0.4);

  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom:0px;
  flex-direction: column;
}
.header__mobile-catalog-2{

}
.header__mobile-catalog-2__c{

  flex-direction: column;
  align-items: center;

  text-align: center;

  background-color: #fff;

  border-radius: 0px 0px 15px 15px;
  padding: 50px 20px 15px 20px;
}
.header__mobile-catalog-2__option{
  margin-bottom: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.header__mobile-catalog-c__close{
  margin-top: 40px;
  cursor: pointer;
}
.header__mobile-catalog-2__close{

}
.header__mobile-catalog-2__close__i{
  width: 16px;
}