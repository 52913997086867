
.article {
  margin: 0 auto;
  max-width: 1660px;
  margin-top: 40px;
  margin-bottom: 50px;
  padding: 70px 80px 90px 80px;

  @media screen and (max-width: 960px) {
    padding: 20px 60px;
  }
  @media screen and (max-width: 840px) {
    padding: 20px 40px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 560px) {
    padding: 20px 20px;
    margin-top: 10px;
  }


  &-cards {
    max-width: 1600px;
    margin: 0 auto;
    padding: 60px 50px 60px 50px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    border-top: 2px solid #efefef;

    @media screen and (max-width: 986px) {
      padding: 20px 10px 30px 10px;
    }

  }

  &__title {
    margin-bottom: 35px;

    h1 {
      max-width: 800px;
      margin: 0 auto;
      margin-top: 20px;
      padding: 0 80px;
      font-size: 50px;
      letter-spacing: 1px;
      line-height: 60px;
      font-weight: 600;

      @media screen and (max-width: 986px) {
        font-size: 30px;
        padding: 0 40px;
        line-height: 1.4;
      }
      @media screen and (max-width: 822px) {
        font-size: 26px;
        padding: 0 10px;
        letter-spacing: normal;
      }
      @media screen and (max-width: 639px) {
        font-size: 24px;
        padding: 0px;

      }
    }
  }

  &__preview {
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 40px;
    padding: 0 80px;
    font-size: 22px;
    line-height: 35px;
    font-weight: 500;

    @media screen and (max-width: 986px) {
      padding: 0 40px;
      font-size: 20px;
      line-height: 1.4;
    }
    @media screen and (max-width: 822px) {
      padding: 0 10px;
    }
    @media screen and (max-width: 560px) {
      font-size: 16px;
      padding: 0px;
    }
  }

  &__info {
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 60px;
    padding: 0 80px;
    letter-spacing: 2px;

    @media screen and (max-width: 986px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 822px) {
      padding: 0 10px;
    }

    &__date {
      font-size: 14px;
      font-weight: 300;
      color: #8a8a8a;
      
    }

    &__categories {
      font-weight: 500;
      font-size: 14px;
      color: #f05656!important;
      cursor: pointer;
      text-decoration: none;
    }
  }

  &__img {
    margin: 0 auto;
    margin-bottom: 60px;

    max-width: 1000px;
    max-height: 380px;
    overflow: hidden;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__i {
      max-width: 100%;
      object-fit: cover;
    }
  }

  &__body {
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 70px;

    font-size: 18px;
    line-height: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h3 {
      margin: 0px;
      
      font-size: 40px;

      line-height: 1.4;
      width: 100%;
    }

    img {
      max-width: 100%;
      object-fit: cover;
    }

    div {
      width: 100%;
    }

    a {
      color: #f27676;
    }

    .quote {
      border-left: 2px solid #aaa;
      padding: 10px 0 10px 25px;
      margin-top: 20px;
      margin-bottom: 26px;
      margin-left: 10px;
      color: #666;
    }

    ol {
      width: 100%;

      @media screen and (max-width: 986px) {
        width: auto;
      }

      li {
        margin-bottom: 30px;
      }
    }
  }

  &__share {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;

    &__title {
      font-size: 34px;
      font-weight: 500;
      margin-bottom: 37px;

      @media screen and (max-width: 986px) {
        font-size: 24px;
      }
    }

    &__description {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 20px;
    }
  }
}

